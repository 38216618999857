<template>
  <div class="cyberinsurance">
    <CyberInsuranceTitle />
    <CyberInsuranceContent />
    <CyberInsuranceFooter />
  </div>
</template>

<script>
import CyberInsuranceTitle from "../components/CyberInsuranceTitle.vue";
import CyberInsuranceContent from "../components/CyberInsuranceContent.vue";
import CyberInsuranceFooter from "../components/CyberInsuranceFooter.vue";
export default {
  name: "cyberinsurance",
  components: {
    CyberInsuranceTitle,
    CyberInsuranceContent,
    CyberInsuranceFooter,
  },
  mounted() {
    sessionStorage.removeItem("infoUser");
  },
};
</script>
