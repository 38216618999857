<template>
  <div class="div-padding bg-content">
    <b-container class="pt-5 pb-5 wrapper">
      <b-row id="cyberInsTarget" class="justify-content-center">
        <h3>
          {{ $t("website.insurance.content.title") }}
          <span class="highlight">{{
            $t("website.insurance.content.p1title")
          }}</span>

          {{ $t("website.insurance.content.p2title") }}
        </h3>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <span class="text-cus1">{{ $t("website.insurance.content.pt1") }}</span>
      </b-row>

      <b-row id="cyberInsDiv" class="d-flex justify-content-center">
        <form
          accept-charset="UTF-8"
          v-on:submit.prevent="onSubmit()"
          method="POST"
          enctype="multipart/form-data"
          id="ajaxForm"
        >
          <div class="form-box">
            <b-row class="loading" :class="{ loadingDiv: loading }"></b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col class="pt-2">
                <label for="title">{{
                  $t("website.insurance.forminsurance.pt1")
                }}</label
                ><span>*</span>
                <b-form-select v-model="dtitle" :options="vtitle" required>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col class="pt-2 col-lg-6 col-md-12">
                <label for="firstname">{{
                  $t("website.insurance.forminsurance.pt2")
                }}</label>
                <b-form-input
                  v-model="dfirstname"
                  type="text"
                  :placeholder="$t('website.insurance.forminsurance.pp2')"
                  required
                ></b-form-input>
              </b-col>

              <b-col class="pt-2 col-lg-6 col-12">
                <label for="lastname">{{
                  $t("website.insurance.forminsurance.pt3")
                }}</label>
                <b-form-input
                  v-model="dlastname"
                  type="text"
                  :placeholder="$t('website.insurance.forminsurance.pp3')"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col class="pt-2 col-lg-6 col-12">
                <label for="opnric">{{
                  $t("website.insurance.forminsurance.pt4")
                }}</label>
                <b-col
                  class="
                    pt-0
                    pl-0
                    pr-0
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <button
                    @click="selectIdentity('opt1')"
                    :class="{ active: isActiveOpt1 }"
                    class="button-select"
                  >
                    {{ $t("website.insurance.forminsurance.pp4") }}
                  </button>
                  <button
                    @click="selectIdentity('opt2')"
                    :class="{ active: isActiveOpt2 }"
                    class="button-select"
                  >
                    {{ $t("website.insurance.forminsurance.pp41") }}
                  </button>
                </b-col>
              </b-col>

              <b-col class="pt-2 col-lg-6 col-md-12">
                <label for="nric">{{
                  $t("website.insurance.forminsurance.pt5")
                }}</label>
                <b-form-input
                  v-model="dnric"
                  type="text"
                  :placeholder="placeholdertext"
                  @keyup="filterNumeric(dnric)"
                  required
                  :maxlength="isActiveOpt1 === true ? 12 : 9"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col class="pt-2 col-lg-6 col-12">
                <label for="email">{{
                  $t("website.insurance.forminsurance.pt6")
                }}</label>
                <b-form-input
                  v-model="demail"
                  type="text"
                  :placeholder="$t('website.insurance.forminsurance.pp6')"
                  required
                  @blur="validateEmail"
                  :class="{ 'invalid-email': !isValidEmail }"
                ></b-form-input>
                <span v-if="!isValidEmail" style="color: red"
                  >Invalid email format</span
                >
              </b-col>

              <b-col class="pt-2 col-lg-6 col-12">
                <label for="contact">{{
                  $t("website.insurance.forminsurance.pt7")
                }}</label>
                <b-form-input
                  v-model="dcontact"
                  type="text"
                  :placeholder="$t('website.insurance.forminsurance.pp7')"
                  required
                  @keyup="filterNumeric(dcontact)"
                  maxlength="12"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex justify-content-between">
              <b-col class="pt-2">
                <label for="address">{{
                  $t("website.insurance.forminsurance.pt8")
                }}</label
                ><span>*</span>
                <b-form-input
                  v-model="daddress"
                  type="text"
                  @input="handleAddress"
                  :placeholder="$t('website.insurance.forminsurance.pp8')"
                  required
                >
                </b-form-input>
                <ul v-if="predictions.length" class="autocomplete-list">
                  <li
                    v-for="prediction in predictions"
                    :key="prediction.description"
                    @click="selectAddress(prediction)"
                  >
                    {{ prediction.description }}
                  </li>
                </ul>
              </b-col>
            </b-row>
            <hr />
            <b-row class="mb-1 mt-5 d-flex justify-content-between">
              <b-col sm="10" class="pt-2"
                ><label for="familyno">{{
                  $t("website.insurance.forminsurance.pt9")
                }}</label>
              </b-col>
              <b-col sm="2">
                <b-form-input
                  v-model="familyno"
                  type="number"
                  placeholder="0"
                  :min="minValue"
                  @input="handleInput(familyno)"
                  required
                  style="text-align: center"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col sm="10" class="pt-2"
                ><label for="gadgetno">{{
                  $t("website.insurance.forminsurance.pt10")
                }}</label>
              </b-col>
              <b-col sm="2">
                <b-form-input
                  v-model="gadgetno"
                  type="number"
                  :min="minValue"
                  placeholder="0"
                  required
                  pattern="^(https?://)?([a-zA-Z0-9]([a-zA-ZäöüÄÖÜ0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$"
                  style="text-align: center"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col sm="10" class="pt-2"
                ><label for="install">{{
                  $t("website.insurance.forminsurance.pt11")
                }}</label>
              </b-col>
              <b-col sm="2" class="d-flex pt-2 justify-content-around">
                <label>
                  <input type="radio" value="yes" v-model="selectedOption1" />
                  Yes
                </label>

                <label>
                  <input type="radio" value="no" v-model="selectedOption1" />
                  No
                </label>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col sm="10" class="pt-2"
                ><label for="information">{{
                  $t("website.insurance.forminsurance.pt12")
                }}</label>
              </b-col>
              <b-col sm="2" class="d-flex pt-2 justify-content-around">
                <label>
                  <input type="radio" value="yes" v-model="selectedOption2" />
                  Yes
                </label>

                <label>
                  <input type="radio" value="no" v-model="selectedOption2" />
                  No
                </label>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col sm="10" class="pt-2"
                ><label for="antivirus">{{
                  $t("website.insurance.forminsurance.pt13")
                }}</label>
              </b-col>
              <b-col sm="2" class="d-flex pt-2 justify-content-around">
                <label>
                  <input type="radio" value="yes" v-model="selectedOption3" />
                  Yes
                </label>

                <label>
                  <input type="radio" value="no" v-model="selectedOption3" />
                  No
                </label>
              </b-col>
            </b-row>
            <b-row
              class="mt-5 mb-2 d-flex justify-content-center align-items-center"
            >
              <b-col>
                <b-form-checkbox
                  id="agreebox"
                  v-model="agreebox"
                  name="agreebox"
                  value="accepted"
                  unchecked-value="not_accepted"
                  style="font-size: 14px"
                  required
                >
                  {{ $t("website.insurance.forminsurance.terms") }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-center">
              <b-button type="submit" class="button-submit" id="submitbtn">
                <span v-if="isLoading"> Please Wait... </span>
                <span v-else>{{
                  $t("website.insurance.forminsurance.btnSubmit")
                }}</span>
              </b-button>
            </b-row>
            <b-row class="mb-3">
              <b-col sm="4" class="pt-2"></b-col>
              <b-col sm="8">
                <b-modal v-model="modalSuccessbox">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon
                  >&nbsp; {{ $t("website.contactus.text1") }}
                </b-modal>
                <b-modal v-model="modalFailbox">
                  <b-icon icon="x-circle-fill" variant="danger"></b-icon>&nbsp;
                  {{ $t("website.contactus.text2") }}</b-modal
                >
                <b-modal v-model="modalAgreebox"
                  >{{ $t("website.contactus.text3") }}.</b-modal
                >
              </b-col>
            </b-row>
          </div>
        </form>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/loader.scss";

.invalid-email {
  border: 2px solid red;
  border-radius: 5px;
}
hr {
  width: 100%;
  border: 0.5px solid #bdbdbd;
  border-radius: 10px;
}

h3 {
  color: var(--black-900, #12070b);
  text-align: center;
  width: 792px;
  //width: 440px;

  /* H3 */
  font-family: Jost;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.highlight {
  background: linear-gradient(159deg, #0f3078 0%, #53aafc 92.42%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Jost;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 768px) {
  .h3 {
    font-size: 22px;
    font-weight: 500;
  }

  .highlight {
    font-size: 22px;
    font-weight: 700;
  }
}

.text-cus1 {
  color: var(--black-700, #8a8a8a);
  /* SM */
  font-family: Jost;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5%;
}

.form-box-white {
  background: #fefefe;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5%;
  //width: 700px;
}

.button-select {
  width: 150px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  //flex-shrink: 0;

  margin-top: 0;
  border-radius: 8px;
  border: 1px solid var(--black-500, #dfdfdf);
  background: var(--black-100, #fefefe);

  color: var(--black-600, #bdbdbd);

  /* Body */
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button-submit {
  display: flex;
  width: 300px;
  height: 41px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--blue-700, #2963b5);

  /* Body */
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.active {
  /* Styles for the active button */
  border-radius: 8px;
  background: var(--blue-700, #2963b5);

  color: var(--black-100, #fefefe);

  /* Btn2 */
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

$large: 992px;
.bg-content {
  @import "../assets/styles/all.scss";

  background-image: url("../assets/img/cyber-insurance-div1-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-top: 1px solid $color-border;

  @media screen and (min-width: $large) {
    .button-select {
      width: 190px;
      height: 41px;
    }

    .button-submit {
      width: 800px;
      height: 41px;
    }
  }
}

.div-padding {
  padding: 0% 5%;
}

.autocomplete-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: white;
  position: absolute;
  width: auto;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.autocomplete-list li {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-list li:hover {
  background-color: #f0f0f0;
}
</style>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  components: {},
  data() {
    return {
      vtitle: [
        { value: null, text: "What is your title?" },
        {
          value: "Mr",
          text: "Mr.",
        },
        {
          value: "Mrs",
          text: "Mrs.",
        },
        {
          value: "Ms",
          text: "Ms.",
        },
        {
          value: "Miss",
          text: "Miss.",
        },
      ],
      isLoading: false,
      placeId: "",
      predictions: [],
      selectedSuggestion: null,
      terms: [],

      dtitle: "",
      dfirstname: "",
      dlastname: "",
      opnric: "NRIC",
      dnric: "",
      placeholdertext:
        this.$t("website.insurance.forminsurance.pp5") + "ID No.",
      isActiveOpt1: true,
      isActiveOpt2: false,

      demail: "",
      isValidEmail: true,
      dcontact: "",
      daddress: "",
      dterms: "",

      familyno: "",
      gadgetno: "",
      minValue: 0, // Set your desired minimum value here

      selectedOption1: "no",
      selectedOption2: "no",
      selectedOption3: "no",
      agreebox: "",
      modalSuccessbox: false,
      modalFailbox: false,
      modalAgreebox: false,
      message: "",
      loading: true,
    };
  },
  created() {
    // Access the route parameters using this.$route.params
    this.dfirstname = this.$route.params.name;
    this.demail = this.$route.params.email;
    this.dcontact = this.$route.params.phone;
  },
  methods: {
    submit(response) {
      return response;
    },
    handleInput(value) {
      // Remove spaces, special characters, consecutive dots, and consecutive dashes
      this.familyno = value.replace(/[^\d.-]/g, "");
      this.familyno = value.replace(/\s+/g, " ");
      this.familyno = value.replace(/\.\./g, ".");
      this.familyno = value.replace(/--/g, "-");

      if (this.familyno < this.minValue) {
        this.familyno = this.minValue;
      }
    },
    validateEmail() {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      this.isValidEmail = emailPattern.test(this.demail);
    },
    selectIdentity(option) {
      if (option == "opt1") {
        this.dnric = "";
        this.isActiveOpt1 = true;
        this.isActiveOpt2 = false;
        this.opnric = "NRIC";
        this.placeholdertext =
          this.$t("website.insurance.forminsurance.pp5") + "ID No.";
      } else if (option == "opt2") {
        this.dnric = "";
        this.isActiveOpt1 = false;
        this.isActiveOpt2 = true;
        this.opnric = "PASSPORT";
        this.placeholdertext =
          this.$t("website.insurance.forminsurance.pp5") + "Passport ";
      }
      return this.opnric;
    },
    filterNumeric(value) {
      if (this.opnric == "NRIC") {
        // Remove spaces, special characters, consecutive dots, and consecutive dashes
        if (!/^\d*$/.test(value)) {
          this.clearInput(); // Clear input when wrong input for NRIC
        }
      }
      if (this.opnric == "PASSPORT") {
        // Remove spaces, special characters, consecutive dots, and consecutive dashes
        if (!/^\d*$/.test(value)) {
          this.clearInput(); // Clear input when wrong input for NRIC
        }
      }
      if (this.dcontact != "") {
        if (!/^\d*$/.test(value)) {
          this.clearInput(); // Clear input when wrong input for NRIC
        }
      }
    },
    clearInput() {
      if (this.opnric === "NRIC") {
        this.dnric = this.dnric.replace(/[^0-9]/g, "");
      }
      if (this.opnric === "PASSPORT") {
        this.dnric = this.dnric.replace(/[^a-zA-Z0-9]/g, "");
      }
      if (
        typeof this.dcontact === "string" ||
        this.dcontact instanceof String
      ) {
        this.dcontact = this.dcontact.replace(/[^0-9]/g, "");
      }
    },
    setSessionDataWithExpiry(key, value, minutesToExpiry) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + minutesToExpiry * 60 * 1000, // Expiry time in milliseconds
      };
      sessionStorage.setItem(key, JSON.stringify(item));
    },
    selectAddress(prediction) {
      this.daddress = prediction.description;
      this.dterms = prediction.terms;
      this.placeId = prediction.place_id;
      this.predictions = [];
      this.terms = [];
      // console.log(prediction);
    },
    handleAddress() {
      if (this.daddress === "") {
        this.predictions = [];
        return;
      }

      if (this.daddress != "") {
        const formData = new FormData();
        formData.append("address", this.daddress);

        axios
          .post(
            "https://insuranceapi.tekkis.my/Webv2/microsite/googleAddressAPI",
            formData,
            {
              headers: {
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.predictions = response.data.place;
            // console.log(response.data.place);
          });
      }
    },
    onSubmit() {
      if (this.agreebox != "") {
        this.message = "";
        this.isLoading = true;
        document.getElementById("submitbtn").disabled = true;
        var opnric = this.selectIdentity();
        const formData = new FormData();
        formData.append("title", this.dtitle);
        formData.append("firstname", this.dfirstname);
        formData.append("lastname", this.dlastname);
        formData.append("opnric", opnric);
        formData.append("nric", this.dnric);

        formData.append("email", this.demail);
        formData.append("contact", this.dcontact);
        formData.append("address", this.daddress);
        formData.append("addressTerms", this.dterms);
        formData.append("family", this.familyno);
        formData.append("gadget", this.gadgetno);

        formData.append("selectedOption1", this.selectedOption1);
        formData.append("selectedOption2", this.selectedOption2);
        formData.append("selectedOption3", this.selectedOption3);
        formData.append("agreebox", this.agreebox);
        formData.append("placeId", this.placeId);

        axios
          .post(
            "https://insuranceapi.tekkis.my/Webv2/microsite/enquiryCyberInsurance",
            formData,
            {
              headers: {
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            //this.loading = true;
            if (response.data.status == "true") {
              //console.log(response.data);

              const jsonData = response.data;
              const encryptionKey = "your-secret-key";
              const jsonDataString = JSON.stringify(jsonData);
              const encryptedData = CryptoJS.AES.encrypt(
                jsonDataString,
                encryptionKey
              ).toString();
              this.setSessionDataWithExpiry("infoUser", encryptedData, "3");
              //console.log("API Response:", encryptedData);
              this.$router.push({
                name: "cyberinsurance-pay",
                params: { receivedData: encryptedData },
              });
            } else {
              this.modalFailbox = true;
            }
            document.getElementById("submitbtn").disabled = false;
            // Clear the form data by resetting the data properties to their initial values
            this.dtitle = "";
            this.dfirstname = "";
            this.dlastname = "";
            this.dnric = "";

            this.demail = "";
            this.dcontact = "";
            this.daddress = "";
            this.dterms = "";
            this.familyno = "";
            this.gadgetno = "";

            this.selectedOption1 = "";
            this.selectedOption2 = "";
            this.selectedOption3 = "";
            this.agreebox = "";
            this.placeId = "";
          });
      } else {
        this.isLoading = false;
        this.modalAgreebox = true;
      }
    },
  },
  mounted() {},
};
</script>
