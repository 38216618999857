<template>
  <div class="div-padding">
    <b-container class="pb-4wrapper title">
      <b-row class="d-flex justify-content-between align-items-center">
        <b-col class="p-0 col-lg-5">
          <img
            class="div-image"
            src="@/assets/img/cyber-insurance-banner2.webp"
            alt=""
          />
        </b-col>
        <b-col class="pl-lg-5 pl-sm-0 col-lg-5">
          <b-col>
            <span
              class="xl-font-size"
              v-html="$t('website.insurance.formfooter.title')"
            ></span>
          </b-col>
          <b-col>
            <span
              class="md-font-size"
              v-html="$t('website.insurance.formfooter.pt1')"
            ></span>
          </b-col>
          <b-col class="mt-3">
            <router-link :to="{ path: '' }">
              <button class="button-insurance" @click="openNewWindow">
                {{ $t("website.insurance.formfooter.pt2") }}
              </button>
            </router-link>
          </b-col>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
$large: 992px;
.bg-content {
  // background: #e5e5e5;
  // background-repeat: repeat;
  // background-size: contain;
  // background-position: top;

  @media screen and (min-width: $large) {
    // background-position: center;
    // background-size: cover;
    // position: relative;
  }
}
@media (max-width: 768px) {
  .mt-sm-4-custom {
    margin-top: 3.5rem !important;
  }
  .div-image {
    width: 300px !important;
  }
  .pl-5 {
    padding: 0 !important;
  }
}

@media (min-width: $large) {
  .div-image {
    width: 200px;
  }
}

.div-padding {
  padding: 0% 5%;
}

span {
  color: var(--black-900, #12070b);

  /* H3 */
  font-family: Jost;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-1 {
  color: var(--black-900, #12070b);
  /* H1 */
  font-family: Jost;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-2 {
  font-family: Jost;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #4f4f4f;
}

.button-insurance {
  display: inline-flex;
  height: 46px;
  padding: 10px 50px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  gap: 10px;
  border-radius: 8px;
  background: linear-gradient(
    49deg,
    #0f3078 0%,
    #3c84d8 54.69%,
    #7dc5fd 95.83%
  );

  /* Btn1 */
  text-align: center;
  font-family: Jost;
  font-size: 18px; // original 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.79px;
}

.text-cus1 {
  color: var(--black-700, #8a8a8a);
  /* SM */
  font-family: Jost;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-image {
  width: 550px;
  height: auto;
  flex-shrink: 0;
  border-radius: 15px;
  background: lightgray -0.55px 0px / 100.075% 137.262% no-repeat;
  // background: url(<path-to-image>),
  //   lightgray -0.55px 0px / 100.075% 137.262% no-repeat;
}
</style>

<script>
export default {
  methods: {
    openNewWindow() {
      const url = "https://www.tekkis.com.my/cyberinsurance"; // Replace this with the URL you want to open in the new window
      const windowName = "_blank"; // Name of the new window (_blank opens in a new tab)

      // You can specify additional window features (size, position, etc.) as a comma-separated string
      const windowFeatures = "width=800,height=600";

      // Open the new window
      window.open(url, windowName, windowFeatures);
    },
  },
};
</script>
